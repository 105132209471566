
/**
 * Module dependencies.
 */

import { FieldError } from 'react-hook-form';
import {
  FormControl,
  FormGroup,
  HelpText,
  Label,
  Sibling,
  Wrapper
} from 'src/components/core/forms/fields-styled-components';

import { forwardRef, useState } from 'react';
import { units } from '@untile/react-components';
import IconButton from 'src/components/core/buttons/icon-button';
import eyeCloseIcon from 'src/assets/svg/eye-close.svg';
import eyeOpenIcon from 'src/assets/svg/eye.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: any,
  className?: string,
  disabled?: boolean,
  error?: FieldError,
  helpText?: string,
  id?: string,
  isRequired?: boolean,
  label: string,
  name?: string,
  placeholder?: string
};

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  margin: ${units(-0.5)};
  text-decoration: none;
`;

/**
 * `PasswordInput` component.
 */

const PasswordInput = forwardRef<any, Props>((props: Props, ref: any) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    isRequired,
    label,
    name,
    ...rest
  } = props;

  const hasError = !isEmpty(error);
  const [showPassword, setShowPassword] = useState<boolean>();

  return (
    <FormGroup
      className={className}
      disabled={disabled}
    >
      <Label isRequired ={isRequired}>
        {label}
      </Label>

      <Wrapper>
        <FormControl
          disabled={disabled}
          hasError={hasError}
          hasSibling
          id={id ?? name}
          name={name}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          {...rest}
        />

        <Sibling>
          <StyledIconButton
            disabled={disabled}
            icon={showPassword ? eyeOpenIcon : eyeCloseIcon}
            iconSize={units(3)}
            onClick={() => setShowPassword(!showPassword)}
          />
        </Sibling>
      </Wrapper>

      {hasError && (
        <HelpText hasError>
          {error?.message}
        </HelpText>
      )}

      {helpText && !hasError && (
        <HelpText>
          {helpText}
        </HelpText>
      )}
    </FormGroup>
  );
});

/**
 * `PasswordInput` display name.
 */

PasswordInput.displayName = 'PasswordInput';

/**
 * Export `PasswordInput` component.
 */

export default PasswordInput;
