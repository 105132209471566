
/**
 * Module dependencies.
 */

import { ChangeEventHandler, ReactNode, forwardRef } from 'react';
import { color, units } from '@untile/react-components';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `CheckboxProps` type.
 */

export type CheckboxProps = {
  alignment?: 'flex-start' | 'center' | 'flex-end',
  'aria-describedby'?: string,
  'aria-label'?: string,
  checked?: boolean,
  className?: string,
  disabled?: boolean,
  label?: ReactNode,
  name?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  reverse?: boolean,
  value?: any
};

/**
 * Checkbox size.
 */

const checkboxSize = units(3);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<Pick<CheckboxProps, 'disabled'>>`
  padding: ${units(1)} 0;
  position: relative;

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `CheckmarkWrapper` styled component.
 */

const CheckmarkWrapper = styled.div`
  background-color: ${color('yellow200')};
  border: calc(${checkboxSize} / 12) solid ${color('yellow400')};
  border-radius: calc(${checkboxSize} / 3);
  grid-area: checkbox;
  height: ${checkboxSize};
  transition: border-color ${theme('animations.fastTransition')};
  width: ${checkboxSize};
`;

/**
 * `Checkmark` styled component.
 */

const Checkmark = styled.div`
  background-color: ${color('primary')};
  border-radius: calc(${checkboxSize} / 6);
  height: calc(7 * ${checkboxSize} / 12);
  margin: calc(${checkboxSize} / 8);
  opacity: 0;
  transition: ${theme('animations.fastTransition')};
  transition-property: background-color, opacity;
  width: calc(7 * ${checkboxSize} / 12);
`;

/**
 * `Label` styled component.
 */

const Label = styled.label<Pick<CheckboxProps, 'alignment' | 'reverse'> & { hasLabel: boolean }>`
  align-items: ${prop('alignment', 'center')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${ifProp('hasLabel', 0, 10)}px;
  grid-template-areas: 'checkbox label';
  grid-template-columns: ${checkboxSize} calc(100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px) ${checkboxSize};

  ${ifProp('reverse', css`
    grid-template-areas: 'label checkbox';
    grid-template-columns: calc(100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px) ${checkboxSize};
  `)}
`;

/**
 * `LabelText` styled component.
 */

const LabelText = styled.span`
  color: ${color('textColor')};
  font-family: ${theme('typography.fontFamily.sansSerif')};
  font-size: 14px;
  font-weight: 400;
  grid-area: label;
  line-height: 24px;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  cursor: pointer;
  grid-area: checkbox;
  height: ${checkboxSize};
  opacity: 0;
  width: ${checkboxSize};
  z-index: 1;

  &:checked {
    & ~ ${CheckmarkWrapper} * {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    & ~ ${LabelText} {
      opacity: 0.75;
    }

    & ~ ${CheckmarkWrapper} {
      border-color: ${color('yellow500')};
    }
  }

  ${ifProp('disabled', css`
    & ~ ${LabelText} {
      opacity: 0.25;
    }

    & ~ ${CheckmarkWrapper} * {
      background-color: ${color('yellow300')};
    }

    & ~ ${CheckmarkWrapper} {
      border-color: ${color('yellow300')};
    }
  `)}
`;

/**
 * `Checkbox` component.
 */

const Checkbox = forwardRef<any, CheckboxProps>((props: CheckboxProps, ref: any) => {
  const { alignment, className, disabled, label, reverse, ...rest } = props;

  return (
    <Wrapper
      className={className}
      disabled={disabled}
    >
      <Label
        alignment={alignment}
        hasLabel={!label}
        reverse={reverse}
      >
        <Input
          disabled={disabled}
          ref={ref}
          type={'checkbox'}
          {...rest}
        />

        {label && (
          <LabelText>
            {label}
          </LabelText>
        )}

        <CheckmarkWrapper>
          <Checkmark />
        </CheckmarkWrapper>
      </Label>
    </Wrapper>
  );
});

/**
 * `Checkbox` display name.
 */

Checkbox.displayName = 'Checkbox';

/**
 * Export `Checkbox` component.
 */

export default Checkbox;
