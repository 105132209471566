
/**
 * Module dependencies.
 */

import { getKeyFromObject } from './objects';
import { networkErrors } from 'src/core/api-config/errors';

/**
 * `NetworkErrorOptions` type.
 */

type NetworkErrorOptions = {
  basePath: string,
  errorCode: string | null,
  name: keyof typeof networkErrors
};

/**
 * Export `getNetworkErrorTranslatedKey`.
 *
 * Shape on translations: ${key}.errors.network.${error}.
 * The default ${error} key is `default`;
 */

export function getNetworkErrorTranslatedKey(options: NetworkErrorOptions) {
  const { basePath, errorCode, name } = options;

  if (!errorCode) {
    return `${basePath}errors.network.default`;
  }

  const networkError = networkErrors[name];
  const hasErrorCode = Object
    .values(networkError)
    // @ts-ignore
    .includes(errorCode);

  const errorTranslatedKey = hasErrorCode ? getKeyFromObject(networkError, errorCode) : 'default';

  return `${basePath}errors.network.${errorTranslatedKey}`;
}
