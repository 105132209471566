
/**
 * Module dependencies.
 */

import { FieldError } from 'react-hook-form';
import {
  FormControl,
  FormGroup,
  HelpText,
  Label,
  Sibling,
  Wrapper
} from 'src/components/core/forms/fields-styled-components';

import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * Export `InputProps` type.
 */

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  as?: any,
  className?: string,
  error?: FieldError,
  helpText?: string,
  id?: string,
  isRequired?: boolean,
  label?: string,
  onSiblingClick?: () => void,
  readOnly?: boolean,
  rows?: number,
  sibling?: ReactNode,
  type?: string
};

/**
 * `Input` component.
 */

const Input = forwardRef((props: InputProps, ref: any) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    isRequired,
    label,
    name,
    onSiblingClick,
    sibling,
    type,
    ...rest
  } = props;

  const hasError = !isEmpty(error);

  return (
    <FormGroup
      className={className}
      disabled={disabled}
    >
      {label && (
        <Label isRequired={isRequired}>
          {label}
        </Label>
      )}

      <Wrapper>
        <FormControl
          disabled={disabled}
          hasError={hasError}
          hasSibling={!!sibling}
          id={id ?? name}
          name={name}
          ref={ref}
          type={type ?? 'text'}
          {...rest}
        />

        {!!sibling && (
          <Sibling onClick={onSiblingClick}>
            {sibling}
          </Sibling>
        )}
      </Wrapper>

      {hasError && (
        <HelpText hasError>
          {error?.message}
        </HelpText>
      )}

      {helpText && !hasError && (
        <HelpText>
          {helpText}
        </HelpText>
      )}
    </FormGroup>
  );
});

/**
 * `Input` display name.
 */

Input.displayName = 'Input';

/**
 * Export `Input` component.
 */

export default Input;
