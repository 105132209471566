
/**
 * Export `networkErrors`.
 */

export const networkErrors = {
  accountBilling: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  accountChangePassword: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  accountProfile: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  accountSecurity: {
    conflict: 'conflict',
    unauthorized: 'unauthorized'
  },
  createContact: {
    conflict: 'conflict',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  createList: {
    conflict: 'conflict',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  createListContacts: {
    notFound: 'not_found',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  createMessage: {
    validationFailed: 'validation_failed'
  },
  createMessageCost: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  deleteContact: {
    notFound: 'not_found',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  deleteContacts: {
    notFound: 'not_found',
    unauthorized: 'unauthorized'
  },
  deleteList: {
    notFound: 'not_found',
    unauthorized: 'unauthorized'
  },
  deleteListContacts: {
    notFound: 'not_found',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  patchApiKeys: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  postApiKeys: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  recoverPassword: {
    validationFailed: 'validation_failed'
  },
  resetPassword: {
    forbidden: 'forbidden',
    notFound: 'not_found',
    validationFailed: 'validation_failed'
  },
  revokeApiKeys: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  signIn: {
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  signUp: {
    badRequest: 'bad_request',
    conflict: 'conflict',
    forbidden: 'forbidden',
    notFound: 'not_found',
    validationFailed: 'validation_failed'
  },
  updateContact: {
    conflict: 'conflict',
    notFound: 'not_found',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  },
  updateList: {
    conflict: 'conflict',
    notFound: 'not_found',
    unauthorized: 'unauthorized',
    validationFailed: 'validation_failed'
  }
} as const;
