/**
 * Module dependencies.
 */

import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';

/**
 * Constants.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Props` type.
 */

type Props = {
  description?: string;
  title?: string;
};

/**
 * `Metatags` component.
 */

const Metatags = (props: Props) => {
  const { description, title } = props;
  const { t } = useTranslation();
  const defaultTitle = t('common:metatags.title');
  const normalizedPageTitle = useMemo(() => {
    if (title && title === defaultTitle) {
      return title;
    }

    if (title) {
      return `${title} | ${defaultTitle}`;
    }

    return defaultTitle;
  }, [defaultTitle, title]);

  return (
    <Head>
      <title>{normalizedPageTitle}</title>

      {description && <meta content={description} name={'description'} />}

      <meta
        content={isProduction ? 'index,follow' : 'noindex,nofollow'}
        name={'robots'}
      />
    </Head>
  );
};

/**
 * Export `Metatags` component.
 */

export default Metatags;
