
/**
 * Module dependencies.
 */

import { FormData } from 'src/types/session';
import { Token } from 'src/types/api';
import {
  getApiEndpoint,
  handleRequestError,
  setOtpTokenHeader
} from 'src/core/utils/requests';

import request from 'src/core/api-config/axios-instance';

/**
 * `Props` type.
 */

type Props = {
  formData: FormData,
  otpToken?: Token
};

/**
 * Export `signIn`.
 */

export async function signIn({ formData, otpToken }: Props) {
  const endpoint = getApiEndpoint('getAuthToken');

  try {
    const { data } = await request.post(endpoint, formData, {
      ...otpToken && { headers: setOtpTokenHeader(otpToken) }
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
