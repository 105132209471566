
/**
 * Module dependencies.
 */

import { Dispatch, SetStateAction, forwardRef } from 'react';
import { Type } from 'src/components/core/typography';
import { color, media, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import { useTranslation } from 'next-i18next';
import OtpInputComponent from 'react-otp-input';
import Svg from 'src/components/core/svg';
import errorIcon from 'src/assets/svg/error.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  disabled?: boolean,
  hasError?: boolean,
  setValue: Dispatch<SetStateAction<string>>,
  value: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(1)};
  grid-template-areas: 
    'label'
    'input'
    'errorMessage';
  grid-template-columns: 100%;

  ${media.min('ms')`
    grid-template-areas: 
      'label label'
      'input errorMessage';
    grid-template-columns: repeat(2, max-content);
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph).attrs({
  as: 'label',
  size: 'small'
})`
  color: ${color('secondary')};
  grid-area: label;
`;

/**
 * `StyledOtpInputComponent` styled component.
 */

const StyledOtpInputComponent = styled(OtpInputComponent)`
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  ${media.min('xs')`
    margin-right: ${units(2)};
  `}

  > input {
    ${theme('typography.styles.p')}

    border: 1px solid ${color('grey100')};
    border-radius: ${units(1)};
    color: ${color('black')};
    height: ${units(4)};
    outline: none;
    transition: ${theme('animations.defaultTransition')};
    width: ${units(5)} !important;

    &:focus {
      border-color: ${color('grey300')};
    }

    ${ifProp('hasErrored', css`
      border-color: ${color('error')} !important;
    `)}

    ${ifProp('disabled', css`
      cursor: default;
      opacity: 0.8;
      pointer-events: none;
    `)}
  }
`;

/**
 * `ErrorMessage` styled component.
 */

const ErrorMessage = styled(Type.Paragraph).attrs({
  as: 'div',
  size: 'small'
})`
  align-items: center;
  color: ${color('error')};
  display: flex;
  font-weight: 700;
  grid-area: errorMessage;
  line-height: 0;

  span {
    margin-right: 5px;
  }
`;

/**
 * `OtpInput` component.
 */

const OtpInput = forwardRef<any, Props>((props: Props, ref: any) => {
  const { className, disabled, hasError, setValue, value } = props;
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Label>
        {t('common:otp.label')}
      </Label>

      <StyledOtpInputComponent
        containerStyle={{
          gridArea: 'input'
        }}
        hasErrored={hasError}
        isDisabled={disabled}
        isInputNum
        numInputs={6}
        onChange={setValue}
        ref={ref}
        shouldAutoFocus
        value={value}
      />

      {hasError && (
        <ErrorMessage>
          <Svg
            icon={errorIcon}
            size={'22px'}
          />

          {t('common:otp.errorMessage')}
        </ErrorMessage>
      )}
    </Wrapper>
  );
});

/**
 * `OtpInput` display name.
 */

OtpInput.displayName = 'OtpInput';

/**
 * Export `OtpInput` component.
 */

export default OtpInput;
