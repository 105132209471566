
/**
 * Module dependencies.
 */

import { Description, Title } from 'src/components/core/modals/modal';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { Trans, useTranslation } from 'next-i18next';
import { Type } from 'src/components/core/typography';
import { media, units } from '@untile/react-components/dist/styles';
import Button from 'src/components/core/buttons/button';
import OtpInput from 'src/components/core/forms/fields/otp-input';
import styled from 'styled-components';

/**
 * `ResendProps` type.
 */

type ResendProps = {
  setLoadingResend: Dispatch<SetStateAction<boolean>>
};

/**
 * `SubmitProps` type.
 */

type SubmitProps = {
  otpToken: string,
  setOtpToken: Dispatch<SetStateAction<string>>,
  setSubmit: Dispatch<SetStateAction<boolean>>
};

/**
 * `Props` type.
 */

type Props = {
  description?: string,
  isLoading: boolean,
  onClose?: () => void,
  onResend: (props: ResendProps) => void,
  onSubmit: (props: SubmitProps) => void,
  title?: string
};

/**
 * `StyledOtpInput` styled component.
 */

const StyledOtpInput = styled(OtpInput)`
  margin-bottom: ${units(3)};
  padding-top: ${units(4)};
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  display: inline-flex !important;
  font-size: 12px !important;
  line-height: 16px !important;
  
  ${media.min('md')`
    margin-left: ${units(1.5)};
  `}
`;

/**
 * `OtpModalLayout` component.
 */

const OtpModalLayout = (props: Props) => {
  const { description, isLoading, onClose, onResend, onSubmit, title } = props;
  const { t } = useTranslation();
  const otpInputRef = useRef<any>();
  const submitButtonRef = useRef<HTMLButtonElement>();
  const [otpToken, setOtpToken] = useState<string>('');
  const [hasError, setError] = useState<boolean>(false);
  const [isLoadingSubmit, setSubmit] = useState<boolean>(false);
  const isSubmitting = isLoading && isLoadingSubmit;
  const handleSubmit = useCallback(() => {
    if (otpToken.length < 6) {
      setError(true);

      return;
    }

    setSubmit(true);

    onSubmit({ otpToken, setOtpToken, setSubmit });
  }, [onSubmit, otpToken]);

  const [isLoadingResend, setLoadingResend] = useState<boolean>(false);
  const handleResend = useCallback(() => {
    setLoadingResend(true);
    setOtpToken('');

    if (otpInputRef) {
      otpInputRef?.current?.focusInput(0);
    }

    onResend({ setLoadingResend });
  }, [onResend]);

  useEffect(() => {
    if (onClose) {
      setOtpToken('');
      setError(false);
    }
  }, [onClose]);

  useEffect(() => {
    if (hasError && otpToken.length === 6) {
      setError(false);

      return;
    }

    if (submitButtonRef && otpToken.length === 6) {
      submitButtonRef.current.click();
    }
  }, [hasError, otpToken]);

  return (
    <>
      <Title>
        {title ?? t('common:otp.modal.title')}
      </Title>

      <Description>
        {description ?? t('common:otp.modal.description')}
      </Description>

      <StyledOtpInput
        disabled={isSubmitting}
        hasError={hasError}
        ref={otpInputRef}
        setValue={setOtpToken}
        value={otpToken}
      />

      <Button
        disabled={isSubmitting || isLoadingResend}
        isLoading={isSubmitting && !isLoadingResend}
        onClick={handleSubmit}
        ref={submitButtonRef}
        type={'submit'}
      >
        {t('common:actions.confirm')}
      </Button>

      <Type.Small
        as={'p'}
        marginBottom={units(1)}
        paddingTop={units(5)}
      >
        <Trans
          components={[
            <StyledButton
              colorTheme={'tertiary'}
              disabled={isLoadingResend || isSubmitting}
              isLoading={isLoadingResend}
              key={'resend-button'}
              onClick={handleResend}
            >{''}</StyledButton>
          ]}
          i18nKey={'common:otp.resend'}
        />
      </Type.Small>

      <Type.Small as={'p'}>
        <Trans
          components={[
            <StyledButton
              colorTheme={'tertiary'}
              href={`mailto:${process.env.NEXT_PUBLIC_EMAIL_CONTACT}`}
              key={'contact-link'}
            >{''}</StyledButton>
          ]}
          i18nKey={'common:otp.contact'}
        />
      </Type.Small>
    </>
  );
};

/**
 * Export `OtpModalLayout` component.
 */

export default OtpModalLayout;
