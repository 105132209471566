
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { color, media, units } from '@untile/react-components/dist/styles';
import { routes } from 'src/core/routes';
import { svgBackground } from 'src/styles/svg';
import { theme } from 'styled-tools';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useTranslation } from 'next-i18next';
import Button from 'src/components/core/buttons/button';
import Svg from 'src/components/core/svg';
import brandingLogo from 'src/assets/svg/branding-logo.svg';
import brandingShape from 'src/assets/svg/branding-shape-half.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  hasSignupLink?: boolean;
  mobileActions?: ReactNode;
}

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-template-areas:
    '. header  .'
    '. sign-up .'
    '. content .';
  grid-template-columns: ${theme('grid.mobileGutter')}px 1fr ${theme('grid.mobileGutter')}px;
  grid-template-rows: repeat(2, max-content);
  min-height: 100vh;

  ${media.min('md')`
    grid-template-areas:
      'header . .       .'
      'header . content .'
      'header . .       .';
    grid-template-columns: 9fr 1fr 8.5fr 1.5fr;
    grid-template-rows: max-content;
  `}
`;

/**
 * `SignupLink` styled component.
 */

const SignupLink = styled.div`
  align-items: center;
  column-gap: ${units(2)};
  display: grid;
  grid-area: sign-up;
  grid-template-columns: max-content auto;

  ${media.min('md')`
    position: absolute;
    right: ${units(13)};
    top: ${units(4)};
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  grid-area: header;
  padding: ${units(3)} 0;

  ${media.max('md')`
    align-items: center;
    display: grid;
    grid-column-gap: ${units(2)};
    grid-template-areas: 'logo actions';
    grid-template-columns: max-content 1fr;
  `}

  ${media.min('md')`
    ${svgBackground(brandingShape)}

    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100vh;
    padding: 0;
    position: sticky;
    top: 0;
  `}

  ${media.min('xl')`
    background-position: center;
    background-size: 100% auto;
  `}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg)`
  ${media.max('md')`
    grid-area: logo;
  `}

  ${media.min('md')`
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  grid-area: actions;
  justify-self: end;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;

  ${media.min('md')`
    min-height: 100vh;
  `}
`;

/**
 * `AuthenticationLayout` component.
 */

const AuthenticationLayout = (props: Props) => {
  const { children, hasSignupLink = true, mobileActions } = props;
  const isMobile = useBreakpoint('md', 'max');
  const { t } = useTranslation();

  return (
    <Grid>
      {hasSignupLink && (
        <SignupLink>
          {t('common:labels.noAccount')}

          <Button
            colorTheme={'secondary'}
            href={routes.signUp}
          >
            {t('common:actions.signUp')}
          </Button>
        </SignupLink>
      )}

      <Header>
        <Logo
          icon={brandingLogo}
          size={isMobile ? units(16) : units(24)}
        />

        {isMobile && (
          <Actions>
            {mobileActions}
          </Actions>
        )}
      </Header>

      <Content>
        {children}
      </Content>
    </Grid>
  );
};

/**
 * Export `AuthenticationLayout` component.
 */

export default AuthenticationLayout;
