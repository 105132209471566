
/**
 * Module dependencies.
 */

import { FormData } from 'src/types/session';
import { getNetworkErrorTranslatedKey } from 'src/core/utils/errors';
import { routes } from 'src/core/routes';
import { signIn } from 'src/api/session/sign-in';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useSession } from 'src/context/session/context';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslation } from 'next-i18next';
import Modal from 'src/components/core/modals/modal';
import OtpModalLayout from 'src/components/core/otp/otp-modal-layout';

/**
 * `Props` type.
 */

type Props = {
  formData: FormData,
  isOpen: boolean,
  onRequestClose: () => void
};

/**
 * `OtpSignInModal` component.
 */

const OtpSignInModal = (props: Props) => {
  const { formData, isOpen, onRequestClose } = props;
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const router = useRouter();
  const { isLoading, mutate } = useMutation(signIn);
  const { onUpdateToken } = useSession();
  const handleSubmit = useCallback(({ otpToken, setSubmit }) => {
    return mutate({ formData, otpToken }, {
      onError: ({ data }) => {
        showMessage(
          t(getNetworkErrorTranslatedKey({
            basePath: 'sign-in:otpModal.',
            errorCode: data?.code,
            name: 'signIn'
          })),
          { appearance: 'error' }
        );
      },
      onSuccess: ({ token }) => {
        setSubmit(false);
        onUpdateToken(token);
        onRequestClose();
        router.push(routes.home);
        showMessage(
          t('sign-in:form.successMessage'),
          { appearance: 'success' }
        );
      }
    });
  }, [
    formData,
    mutate,
    onRequestClose,
    onUpdateToken,
    router,
    showMessage,
    t
  ]);

  const handleResend = useCallback(({ setLoadingResend }) => {
    return mutate({ formData }, {
      onError: () => {
        setLoadingResend(false);

        showMessage(
          t('sign-in:otpModal.resendSuccessMessage'),
          { appearance: 'success' }
        );
      },
      onSuccess: () => {
        setLoadingResend(false);
      }
    });
  }, [formData, mutate, showMessage, t]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <OtpModalLayout
        isLoading={isLoading}
        onClose={onRequestClose}
        onResend={handleResend}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

/**
 * Export `OtpSignInModal` component.
 */

export default OtpSignInModal;
