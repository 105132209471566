/**
 * Module dependencies.
 */

import { color, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div<{ disabled?: boolean }>`
  margin-bottom: ${units(2)};
  position: relative;
  width: 100%;

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}

  * {
    outline: none;
  }
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label<{ isRequired?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  margin: 0 2px;
  pointer-events: none;
  white-space: nowrap;

  ${ifProp(
    'isRequired',
    css`
      &::after {
        color: ${color('required')};
        content: ' *';
      }
    `
  )}
`;

/**
 * Export `Sibling` styled component.
 */

export const Sibling = styled.div`
  align-items: center;
  background-color: ${color('white')};
  border: 1px solid ${color('grey400')};
  border-bottom-right-radius: ${units(1)};
  border-left: 0;
  border-top-right-radius: ${units(1)};
  color: ${color('black')};
  display: flex;
  height: ${units(6)};
  line-height: 24px;
  padding-right: ${units(1)};
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color;

  ${ifProp(
    'disabled',
    css`
      border-color: ${color('grey200')};
      color: ${color('grey600')};
    `
  )}

  ${ifProp(
    'hasError',
    css`
      border-color: ${color('red')};
      color: ${color('red')};
    `
  )}
`;

/**
 * Export `formControlStyle` constant.
 */

export const formControlStyle = css`
  background-color: ${color('white')};
  border: 1px solid ${color('grey400')};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('black')};
  display: block;
  font-size: 16px;
  height: ${units(6)};
  line-height: 24px;
  margin: ${units(1)} 0;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color;
  width: 100%;

  &::placeholder {
    color: ${color('grey600')} !important;
    opacity: 1;
  }

  &:focus,
  &:focus ~ ${Sibling}, &:hover,
  &:hover ~ ${Sibling} {
    border-color: ${color('grey600')};
  }

  &:focus,
  &:focus ~ ${Sibling} {
    border-color: ${color('grey900')};
    color: ${color('black')};
  }

  ${ifProp(
    'disabled',
    css`
      &,
      & ~ ${Sibling} {
        border-color: ${color('grey200')};
        color: ${color('grey600')};
      }
    `
  )}

  ${ifProp(
    'hasError',
    css`
      &,
      & ~ ${Sibling} {
        border-color: ${color('red')};
        color: ${color('red')};
      }
    `
  )}

  ${ifProp(
    'readOnly',
    css`
      &,
      & ~ ${Sibling} {
        border-color: ${color('grey200')};
        color: ${color('grey600')};
        cursor: default;
        pointer-events: none;
      }
    `
  )}
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input<{
  as?: any;
  disabled?: boolean;
  hasError?: boolean;
  hasSibling?: boolean;
}>`
  padding: ${units(1.5)} ${units(1)};

  ${formControlStyle};

  ${ifProp(
    { as: 'textarea' },
    css`
      height: auto;
      resize: vertical;
    `
  )}

  ${ifProp(
    'hasSibling',
    css`
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
    `
  )}
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled.div<{ hasError?: boolean }>`
  color: ${color('grey700')};
  font-size: 12px;
  line-height: 18px;
  margin: 0 2px;

  ${ifProp(
    'hasError',
    css`
      color: ${color('red')};
    `
  )}
`;

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr min-content;
  position: relative;
`;
