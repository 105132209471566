
/**
 * Module dependencies.
 */

import { FormData } from 'src/types/session';
import { GetStaticProps, NextPage } from 'next';
import { SSRProps } from 'src/types/app';
import { Type } from 'src/components/core/typography';
import { color, media, units } from '@untile/react-components/dist/styles';
import { getNetworkErrorTranslatedKey } from 'src/core/utils/errors';
import { getOtpTokenHeader } from 'src/core/utils/requests';
import { regexes } from 'src/core/utils/regexes';
import { routes } from 'src/core/routes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { signIn } from 'src/api/session/sign-in';
import { theme } from 'styled-tools';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useSession } from 'src/context/session/context';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslation } from 'next-i18next';
import AuthenticationLayout from 'src/components/layout/authentication';
import Button from 'src/components/core/buttons/button';
import Checkbox from 'src/components/core/forms/fields/checkbox';
import Input from 'src/components/core/forms/fields/input';
import Metatags from 'src/components/core/metatags';
import OtpSignInModal from 'src/components/modals/otp-sign-in-modal';
import PasswordInput from 'src/components/core/forms/fields/password';
import RouterLink from 'src/components/core/links/router-link';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `rememberMe` constant.
 */

const rememberMe = 'rememberMe';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(8)} 0 ${units(5)};

  ${media.min('ms')`
    margin: 0 auto;
    padding-top: ${units(12)};
    width: 50%;
  `}

  ${media.min('md')`
    margin: 0;
    width: calc(6 * 100% / 8.5);
  `}
`;

/**
 * `ForgotPasswordLink` styled component.
 */

const ForgotPasswordLink = styled(Type.Paragraph).attrs({
  as: RouterLink,
  size: 'small'
})`
  color: ${color('grey600')};
  display: flex;
  margin-bottom: ${units(4)};
  outline: none;
  text-decoration: underline;
  transition: color ${theme('animations.defaultTransition')};
  width: max-content;

  &:focus,
  &:hover {
    color: ${color('grey800')};
  }
`;

/**
 * `StyledCheckbox` styled component.
 */

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${units(5)};
  padding-left: 0;
`;

/**
 * Is valid email.
 */

function isValidEmail(email: string | null | undefined): boolean {
  return regexes.email.test(email);
}

/**
 * `useRememberMe` hook.
 */

function useRememberMe(email: string | null) {
  const [initialValue, setInitialValue] = useState<string | null>();
  const handleUpdate = useCallback(value => {
    const isValid = isValidEmail(email);

    if (isValid && value) {
      localStorage.setItem(rememberMe, email);

      return;
    }

    localStorage.removeItem(rememberMe);
  }, [email]);

  const handleClick = useCallback(({ target }) => {
    handleUpdate(target.checked);
  }, [handleUpdate]);

  useEffect(() => {
    const rememberMeInitialValue = localStorage.getItem(rememberMe);

    setInitialValue(rememberMeInitialValue);
  }, []);

  return {
    initialValue,
    onClick: handleClick,
    onUpdate: handleUpdate
  };
}

/**
 * `SignIn` page.
 */

const SignIn: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { showMessage } = useSnackbar();
  const { onUpdateToken } = useSession();
  const {
    formState,
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm<FormData & { rememberMe: boolean }>({ mode: 'onBlur' });

  const {
    initialValue: rememberMeInitialValue,
    onClick: onClickRememberMe,
    onUpdate: onUpdateRememberMe
  } = useRememberMe(watch('email'));

  const [isOtpModalOpen, setOtpModalOpen] = useState<boolean>(false);
  const [otpModalData, setOtpModalData] = useState<FormData | null>(null);
  const { isLoading, mutate } = useMutation(signIn);
  const errors = formState?.errors;
  const isSubmitting = isLoading || formState?.isSubmitting;
  const handleSignIn = useCallback((data: FormData & {
    [rememberMe]?: boolean
  }) => {
    const formData = omit(data, [rememberMe]);

    onUpdateRememberMe(data[rememberMe]);

    return mutate({ formData }, {
      onError: ({ data, headers, status }) => {
        const otpToken = getOtpTokenHeader(headers);

        if (status === 401 && otpToken === 'required') {
          setOtpModalOpen(true);
          setOtpModalData(formData);

          return;
        }

        showMessage(
          t(getNetworkErrorTranslatedKey({
            basePath: 'sign-in:',
            errorCode: data?.code,
            name: 'signIn'
          })),
          { appearance: 'error' }
        );
      },
      onSuccess: ({ token }) => {
        onUpdateToken(token);
        router.push(routes.home);
        showMessage(
          t('sign-in:form.successMessage'),
          { appearance: 'success' }
        );
      }
    });
  }, [
    mutate,
    onUpdateRememberMe,
    onUpdateToken,
    router,
    showMessage,
    t
  ]);

  useEffect(() => {
    if (rememberMeInitialValue) {
      setValue('email', rememberMeInitialValue);
      setValue(rememberMe, true);
    }
  }, [rememberMeInitialValue, setValue]);

  return (
    <AuthenticationLayout>
      <Metatags title={t('sign-in:metatags.title')} />

      <Wrapper>
        <Type.H1
          marginBottom={units(3)}
          marginBottomMd={units(5)}
        >
          {t('sign-in:title')}
        </Type.H1>

        <form onSubmit={handleSubmit(handleSignIn)}>
          <Input
            disabled={isSubmitting}
            error={errors?.email}
            isRequired
            label={t('common:forms.labels.email')}
            name={'email'}
            placeholder={t('common:forms.placeholders.email')}
            {...register('email', {
              pattern: {
                message: t('common:forms.rules.emailFormat'),
                value: regexes.email
              },
              required: {
                message: t('common:forms.rules.required'),
                value: true
              }
            })}
            type={'email'}
          />

          <PasswordInput
            disabled={isSubmitting}
            error={errors?.password}
            isRequired
            label={t('common:forms.labels.password')}
            name={'password'}
            placeholder={t('common:forms.placeholders.password')}
            {...register('password', {
              required: {
                message: t('common:forms.rules.required'),
                value: true
              }
            })}
          />

          <ForgotPasswordLink href={routes.recoverPassword}>
            {t('sign-in:form.forgotPasswordLink')}
          </ForgotPasswordLink>

          <StyledCheckbox
            label={t('sign-in:form.labels.rememberMe')}
            name={rememberMe}
            onChange={onClickRememberMe}
            {...register(rememberMe)}
          />

          <Button
            isLoading={isSubmitting}
            type={'submit'}
          >
            {t('sign-in:form.submitButton')}
          </Button>
        </form>
      </Wrapper>

      <OtpSignInModal
        formData={otpModalData}
        isOpen={isOtpModalOpen}
        onRequestClose={() => setOtpModalOpen(false)}
      />
    </AuthenticationLayout>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSRProps> = async ctx => {
  return {
    props: {
      ...await serverSideTranslations(ctx?.locale, ['common', 'sign-in']),
      layout: 'authentication'
    },
    revalidate: 60
  };
};

/**
 * Export `SignIn` page.
 */

export default SignIn;
